/* =======================CAROUSEL BOOTSTRAP CUSTOMS =============================== */

.carousel-item{
  opacity:0;
  transition: opacity 1s !important;
}
.carousel-item.active{
  opacity:1;
  transition: opacity 1s !important;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    visibility: hidden;
   
  }
.carousel-indicators {
left:auto !important ;
}
.carousel-indicators button {
    background-color: #fff !important;
    border: 1px solid var(--font-grey) !important;
    width: 0.4rem !important;
    height: 0.4rem !important;
    border-radius: 50%;
  }
  
.carousel-indicators button:not(:first-child) {
    margin-left: 0.5rem;
  }
  
.carousel-indicators .active {
    border: 1px solid var(--pink) !important;
    background-color: var(--pink) !important;
  }

.ImgHeroDescuento{
    height: 378px;
    width: 100%;
    object-fit: cover;
    border-bottom-right-radius: 50px;
}

.LogoHeroDescuento{
    height: 104px;
    width: 100%;
    object-fit: contain;
}
.btnDescuento{
    font-weight: 500;
    font-size: 15px;
    color: var(--white);
    background-color: var(--red);
    border-radius: 20px;
    padding: 10px 30px;
    border: none;
}
.btnDescuento:hover{
    background-color: var(--pink);
}

@media (max-width: 768px) {
    .btnDescuento{
        font-size: 12px;
        padding: 5px 15px;
    }
    .ImgHeroDescuento{
        height: 204px;
    }
    .LogoHeroDescuento{
        height: 54px;
    }

}