.ContainQR{
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 428px;
    height: 231px;
    background-color: var(--white);
    border-radius: 28px;
}
.ContainQRImg{
    padding-left: 23px;
}
.ContainQRText{
    padding-left: 5px;
    padding-right: 30px;
}
.TitleQR{
    font-size: 20px;
    font-weight: 700;
    color: var(--pink);
}
.SubtitleQR{
    text-align: left;
    font-weight: 400;
    padding-top: 26px;
    font-size: 16px;
}

@media (max-width:800px){
    .ContainQR{
        display: none;
    }
    
}