.market-card{
    width:220px !important;
    transition: all 0.5s;
    border-radius: 15px;
}

.market-card:hover{
    box-shadow: 0px 0px 20px #00000040;
    transform: scale(1.015);
}
.market-card:hover .market-card-img{
    transform: scale(1.0009);
}

.market-card-img{
    width:100%;
    height:100%;
    object-fit: contain;
    transition:  all 0.5s;
}

.subtitle-marketplace{
    font-size: 16px;
    color:var(--font-grey);
    font-weight: 500;
}
.ContainTextMarket{
    height: 70px;
}

.TitleMarket{
    padding: 0;
}

.select-custom{
    width:100%;
    height:40px;
    background-color: transparent;
    border: 1px solid var(--grey);
    padding: 0px 10px;
    font-size: 17px;
    color:var(--font-grey);
    font-weight: 400;
    font-family: 'Source Sans Pro', sans-serif;
    transition: all 0.5s;
}