.Carrusel{
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.CarruselContain{
    overflow-x: auto;
    justify-content: flex-start;
    gap:24px;
    padding-bottom: 45px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.CarruselLink{
    display: flex;
    justify-content: center;
}
.Link{
    cursor: pointer;
    text-decoration: underline;
    color:var(--red);
    font-size: 26px;
    font-weight: bold;
}
.Link:hover{
    color:var(--pink);
}

@media (max-width: 800px) {
    .Link{
        padding-bottom: 92px;
    }
    
    .Carrusel{
        padding-left: 5px;
        padding-right: 5px;
    }
    .CarruselContain{
        justify-content: flex-start;
    }

}