.contain-myaccount{
    background-color: var(--white);
    border-radius: 10px;
}

.sub-menu-categoria{
    letter-spacing: 0px;
    color:var(--font-grey);
    text-align: left;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 17px;
}
.sub-menu-categoria:hover{
  cursor: pointer;
}
.sub-menu-categoria-active{
    font-weight: 600;
    color:var(--black);
}
.main-menu-title{
    font-size: 25px;
    font-weight: 400;
    color:var(--black);
    letter-spacing: 0px;
}
.main-menu-categoria{
    color:var(--black);
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.51px;
}
.main-menu-value{
    font-size: 15px;
    font-weight: 400;
    color:var(--font-grey);
    letter-spacing: 0.51px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.btn-change-password{
    border-radius: 25px;
    border: none;
    font-size: 15px;
    font-weight: 500;
    color:var(--white);
    background-color: var(--red);
    padding:10px 70px;
}
.btn-ver-credencial{
    border-radius: 25px;
    border: none;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0px;
    color:var(--red);
    background-color: var(--white);
    padding: 8px 20px;
}
.mobile-title{
    font-size:20px;
    color: var(--white);
}
.mobile-category{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size:17px;
    color: var(--white);
}
.mobile-category-name{
    font-size:20px;
    font-weight: 600;
    color: var(--white);
}
.mobile-link{
    text-decoration: underline;
    font-size:14px;
    font-weight: 600;
    color: var(--white);
}

