/* @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');
@import url('https://use.typekit.net/egi6jyb.css');
@import './assets/css/fonts.css';
@import './assets/css/colors.css';


a{
  cursor: pointer;
  text-decoration: none !important;
}
h1{
  margin-bottom: 0px !important;
}
p{
  margin-bottom: 0 !important;
}

* {
  margin: 0px ;
  padding: 0px ;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

.App {
 width: 100vw;
}
.cursor-pointer{
  cursor: pointer;
}
.ScrollCustom::-webkit-scrollbar {
  height: 20px;
}
.ScrollCustom::-webkit-scrollbar-thumb {
  border-top: 7px solid var(--white);
  border-bottom: 7px solid var(--white);
  background: var(--pink);
}
.ScrollCustom::-webkit-scrollbar-track{
  background:var(--grey);
  border-top: 9px solid var(--white);
  border-bottom: 9px solid var(--white);
}

.ScrollCustomGrey::-webkit-scrollbar {
  height: 20px;
}
.ScrollCustomGrey::-webkit-scrollbar-thumb {
  border-top: 7px solid var(--light-grey);
  border-bottom: 7px solid var(--light-grey);
  background: var(--pink);
}
.ScrollCustomGrey::-webkit-scrollbar-track{
  background:var(--grey);
  border-top: 9px solid var(--light-grey);
  border-bottom: 9px solid var(--light-grey);
}


@media (max-width: 800px) {
  ::-webkit-scrollbar{
    display: none;
  }
}
