.CardBack{
    transform: perspective(500px) rotateY(0deg);
    transition: all 0.5s ease-in-out;
}
/* .Show{
    display: block !important;
    transform: perspective(500px) rotateY(180deg);
    transition: all 0.5s ease-in-out;
    transform: perspective(500px) rotateY(0deg);
   
}
.Hide{
   
    transform: perspective(500px) rotateY(0deg);
    transition: all 0.5s ease-in-out;
    display: none !important;
} */
.btnNext{
    font-size: 20px;
    font-weight: 500;
    border: none;
    padding: 13px 53px;
    border-radius: 30px;
    background-color:var(--pink);
    color:var(--white);
}
.btnNext:hover{
    background-color:var(--red);
}
.btnPrev{
    text-decoration: underline;
    border: none;
    color: var(--grey);
    background-color: transparent;
}
.CardFront{
    transform: perspective(600px) rotateY(0deg);
}
.CardBack{
    transform: perspective(600px) rotateY(180deg);
    position: absolute;
}
.ContainCardExample{
    transition: all 0.5s ease-in-out;
    backface-visibility: hidden;
}
.CardExample{
    /* width: 386px;
    height: 230px; */
    width: 341px;
    height: 221px;
    background-color: var(--red);
    border-radius: 20px;
    transition: all 0.5s ease-in-out;
    backface-visibility: hidden;
}
.CardFrontBackActive{
    transform: perspective(600px) rotateY(180deg);
}
.CardBackBackActive{
    transform: perspective(600px) rotateY(360deg);
}

.CardFrontFrontActive{
    transform: perspective(600px) rotateY(0deg);
}
.CardBackFrontActive{
    transform: perspective(600px) rotateY(180deg);
}

@media (max-width: 968px){
    .CardExample{
        width: 341px;
        height: 221px;
    }
    .containProgressBar{
        justify-content: center;
        align-items: center;
    }
    .progressBar{
        width: 299px;
    }
}

