.contain-descripcion-nota-cultural p{
    font-size: 20px;
    letter-spacing: 0px;
    color: var(--font-grey);
    font-weight: 400;
}
.contain-descripcion-nota-cultural img{
    max-width: 100%;
}
.titulo-nota-cultural{
    font-size:40px;
    font-weight:700;
    color: var(--red);

}
.contain-text-nota-cultural{
    position: absolute;
    width: 180px;
    height: 90px;
    bottom: 49px;
    right: 32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 0px 30px #00000029;
    border-radius: 14px;
    padding: 10px;
}