::-webkit-scrollbar {
    
  }
.CredContain{
   
    padding-top: 108px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}
.TitleContain{
    position: absolute;
    display: flex;
    width: 40%;
    flex-direction: column;
    gap: 86px;
}
.RightContain{
    display: flex;
    flex-direction: column;
    
}
.Title{

    font-size: 40px;
    text-align: left;
    font-weight: 600;
    color: var(--white);
}
.SubTitle{
    font-size: 20px;
    text-align: left;
    color: var(--white);
}
.SubTitleApp{
    font-size: 20px;
    text-align: left;
    color: var(--white);
}
.ContainImgs{
    left: 440px;
    position: relative;
}
.ContainImgIphone{
    
    transform: rotate(23deg);
    position: relative;
    z-index: 1;
    width: 620px;
    height: 720px;
}
.ImgIphone{
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 2;
}
.ContainImgCard{

    top: 82px;
    left: 312px;
    position: absolute;
    z-index: 0;
    width:520px;
    height: 371px;
}
.ImgCard{
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: -1;
}
.ContainApp{
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    gap: 15px;

}

.Apple .icon{
    background-image:url('../../assets/images/logo-appstore.svg');
    background-repeat: no-repeat;
    width: 31px;
    height: 31px;
}
.Apple:hover .icon{
    background-image:url('../../assets/images/logo-appstorepink.svg');
}
.Google .icon{
    background-image:url('../../assets/images/playstore.svg');
    background-repeat: no-repeat;
    width: 31px;
    height: 31px;
}
.Google:hover .icon{
    background-image:url('../../assets/images/playstorepink.svg');
}
@media (max-width: 1240px) {
    .ContainImgCard
    {
        width:420px;
        height: 271px;

    }
    .ContainImgIphone
    {
        width: 520px;
        height: 620px;
     }
    }
@media (max-width:1125px){
    .CredContain{
        flex-direction: column;
    }
    .TitleContain{
        position: relative;
    }
    .ContainImgs{ 
        margin-top: 25px;
        left: -125px;
    }
    .TitleContain{
        padding-left: 0;
        gap: 20px;
        width: auto;
    }
    .AppContain{
        display: flex;
        flex-direction: column-reverse;
    }
    .ContainApp{
        gap:32px;
        flex-direction: column;
        align-items: center;
    }
    .btnApp{ 
        width: fit-content;
    }
    .Title{
        font-size: 30px;
        padding-bottom: 30px;
        text-align: center;
    }
    
    .SubTitle{
        font-size: 18px;
        text-align: center;
    }
    .SubTitleApp{
        padding-top: 36px;
        text-align: center;
    }
}

@media (max-width: 800px) {
    .ContainImgs {
        left: -76px;
    }
    .ContainImgCard
    {
        left: 194px;
        width:328px;
        height: 234px;
    }
    .ContainImgIphone
    {
        width: 364px;
        height: 447px;
     }
}