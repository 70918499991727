.CardDescubri{
    border: none !important;
    width:251px;
    height:531px;
}
.ContainerImgDescubrilo{
    border-radius: 12px 12px 0px 0px;
    overflow: hidden;
    width:251px;
    height:500px;
    display: flex;
}
.ImgCardDescubrilo{
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    transition: all 0.3s ease-in-out;
}

.TitleOverlay{
    font-weight: 400 !important;
    text-transform: uppercase;
    color: var(--white);
    font-size: 30px;
}
.BodyCardDescubrilo{
    background:transparent linear-gradient(90deg, #E4222C 0%, #EB1F50 71%, #FA1995 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 50px 50px;
}
.TextBodyCardDescubrilo{
    padding: 15px 0;
    color: var(--white);
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0px;
}
.CardDescubri:hover {
    cursor: pointer;
}
.CardDescubri:hover .ImgCardDescubrilo{
    transform: scale(1.2);
}
@media (max-width:768px){
    .CardDescubri{
        width:180px;
        height:358px;
    }
    .TextBodyCardDescubrilo{
        font-size: 16px;
    }
    .ContainerImgDescubrilo{
        width:180px;
        height:338px;
    
    }
    .TitleOverlay{
        font-size: 20px;
    }

}