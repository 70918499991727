.ContainerAllLogin{
    background: transparent linear-gradient(118deg, #E4222C 0%, #EB1F50 71%, #FA1995 100%) 0% 0% no-repeat padding-box;
    height: 100vh;
}
.ContainerInputForm{
    background-color: var(--white);
    border-radius: 35px;
    padding: 20px;
}
.TitleFormLogin{
    color:var(--pink);
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0px;
}
.PFieldLogin{
    text-align: left;
    font: normal normal normal 15px/23px Montserrat;
    letter-spacing: 0.25px;
    color: var(--black)

}
.BtnFormLogin{
    border: none;
    background-color: var(--red);
    color: var(--white);
    padding: 10px 30px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 20px;
}
.LinkLogin{
    color:var(--pink);
    text-decoration: underline !important;
}

@media (max-width: 768px) {
    .ContainerInputForm{
        background-color: transparent;
        width: 100%;
    }
    .TitleFormLogin{
        font-size:20px;
        color: var(--white);
        text-transform: uppercase;
    }
    .PFieldLogin{
        font-size: 15px;
        color: var(--white);
        text-align: center;
    }
    .BtnFormLogin{
        background-color: var(--white);
        color: var(--red);
        text-transform: uppercase;
    }
    .LinkLogin{
        color: var(--white);
    }
    .ContainerAllLogin{
        height: 100vh;
    }
}