.SectionSub1{
    background-image:url("../images/DePC Pass_02_fondo2_cut.png");
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    background-position-y: -53px;
}

@media (max-width:800px){
    .SectionSub1{
        background-position-y: -9px;
}

}
