/*
font-weight: 400; regular
font-weight: 500; medium
font-weight: 600; semibold
font-weight: 700; bold
font-weight: 800; extrabold
*/

/* =========================== H ======================================================== */
.h2Title{
    color: var(--red);
    font-size: 28px;
    padding-bottom: 18px;
    font-weight: 700;
}
.H2Pink36px{
    font-size: 32px;
    font-weight: 700;
    color:var(--pink)
}
.H3Pink30px{
    font-size: 30px;
    font-weight: 700;
    color:var(--pink)
}

/*==================================== P ======================================================== */
.overflow-hidden-elipsis{
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}
.PTitleCarousel{
    font-size:16px;
    font-weight: 500;
    letter-spacing: 0;
}
.PDescuentosBuscar{
    letter-spacing: 0px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
}
.PDescuentosRed{
    letter-spacing: 0px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 17px;
    color: var(--red);
}
.PCategoria{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 17px;
    letter-spacing: 0px;
    color:var(--font-grey);
}
.PRubro{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    letter-spacing: 0.85px;
    color:var(--black);
    font-size: 17px;
}
.PFiltroGrey{
    font-size: 10px;
    font-family: 'Source Sans Pro', sans-serif;
    color: var(--font-grey);
}
.PReinciarFiltros{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    color:var(--black);
    letter-spacing: 1.5px;
    font-size: 11px;
  
}
.PFiltro{
    cursor: pointer;
    color:var(--red);
    letter-spacing: 0;
    font-weight: 500;
    font-size:18px;
    text-align: center;
    text-decoration: underline;
}
.PError{
    font-weight: 500;
    letter-spacing: 0px;
    font-size: 14px;
    color: var(--red);
}
.PMenuMobile{
    color:var(--white);
    letter-spacing: 0;
    font-size: 15px;
    font-weight: 500;
}
.PGrey20px{
    letter-spacing: 0px;
    font-size: 20px;
    color: var(--font-grey);
}
.PCardExample24px{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    letter-spacing: 0px;
    font-size: 24px;
    color: var(--white);
    font-weight: 500;
    text-transform: uppercase;

}
.PFieldTitle{
    letter-spacing: 0px;
    font-size: 24px;
    color: var(--pink);
    font-weight: 500;

}
.PFieldHelpText{
    letter-spacing: 0px;
    font-size: 12px;
    color: var(--font-grey);
    font-weight: 400;
}
.DisableOption{
    color: var(--font-grey);
    font-size: 12px;
    font-weight: 400;
}

@media (max-width: 800px) {
    .PDescuentosRed{
        font-size: 12px;
    }
    .PFieldTitle{
        font-size: 16px;
    }
    .PGrey20px{
        font-size: 14px;
    }
    .H2Pink36px{
        font-size: 27px;
    }
    .h2Title{
        text-align: center;
        font-size: 24px;
    }
}