.NavContainer{
    gap: 20px;
    padding-right: 83px;
    width: 100%;
    height: 49px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.btnLogIn:hover {
    font-weight: bold;
    background-color: var(--red);
}
.btnLogIn::before{
    display: block;
    content: attr(textContent);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}
.btnSub:hover{
    font-weight: bold;
    background-color: var(--red);
}
.btnLogIn{
    border: none;
    height: 26px;
    width: 136px;
    letter-spacing: 1.5px;
    font-size: 11px;
    text-align: center;
    border-radius: 2px;
    color:var(--white);
    background-color: var(--black);
}
.btnSub{
    height: 26px;
    width: 108px;
    border: none;
    padding: 2px;
    letter-spacing: 1.5px;
    font-size: 11px;
    text-align: center;
    border-radius: 2px;
    color:var(--white);
    background-color: var(--pink);
}
.btnMyacc{
    height: 26px;
    width: 108px;
    border: none;
    padding: 2px;
    letter-spacing: 1.5px;
    font-size: 11px;
    text-align: center;
    border-radius: 2px;
    color:var(--white);
    background-color: var(--pink);
}
.btnMyacc:hover{
    color: var(--white);
    background-color: var(--black);
}
.NavbarTitle{
    cursor: pointer;
}
p{
    color:var(--font-grey);
    font-size: 11px;
    letter-spacing: 1.5px;
}
@media (max-width: 1069px) {
   .NavContainer{
      display: none;
   }
    }