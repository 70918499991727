.FooterCred{
    gap:72px;
    padding-top: 189px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.FooterCredContainImg{
    width: 520px;
    height: 371px;
}
.FooterCredImg{
    width: 100%;
    height: 100%;
    object-fit: cover;

}
.H1FooterCred{
    font-size: 30px;
    font-weight: bold;
    color: var(--white);
    text-align: center;
    margin-top: 20px;
}

@media (max-width:800px){
    .FooterCred{
        padding-top: 0;
        display:flex;
        flex-direction: column;
        background-image: url("../../assets/images/fondo_footer.png");
        background-size: cover;
        background-position: top;
    }
    .FooterCredContainImg{
        width: 353px;
        height: 252px;
    }
    .ContainLogoPC{
        display: flex;
        justify-content: center;
    }
    
}