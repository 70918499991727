.ContainCashback{
    overflow: hidden;
    padding-bottom: 40px;
}
.ColCashback{
    left: 0px;
    width: 371px !important;
    height: 194px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: transparent linear-gradient(90deg, #E4222C 0%, #EB1F50 71%, #FA1995 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 97px 97px 0px;
}
.CashBackImg{
    padding-right: 20px;
}
.ContainCashText{
    letter-spacing: 1.2px;
    width: 100%;
    height: 100%;
    position: relative;
    left: 385px;
}
.PCashback{
    color:var(--font-grey);
    line-height: 25px;
    text-align: left;
    font-size: 24px;
    letter-spacing: 1.2px;
    font-weight: 600;
   
}
.PImportantCashBack{
    font-weight: 800;
    font-size: 24px;
    color:var(--red)
}

@media (max-width:1065px){
    .ContainCashText{
        left: 0px;
    }
    .ColCashback{
        width: auto;
        height: auto;
        justify-content: center;
        position: relative;
    }
    .ContainCashback{
        padding-top: 10px;
        padding-bottom: 0px;
        margin-right: 0;
        margin-left: 0;
        align-items: center;
        flex-direction: column;
        width: 100vw;
        background: transparent linear-gradient(90deg, #E4222C 0%, #EB1F50 71%, #FA1995 100%) 0% 0% no-repeat padding-box;
    }
    .PCashback{
        text-align: center;
        color:var(--white)
    }
    .PImportantCashBack{
        color:var(--white);
        font-weight: 700;
    }
    
}
@media (max-width:768px){
    .PImportantCashBack{
        font-size: 16px;
    }
    .PCashback{
        font-size: 16px;
    }


}
        