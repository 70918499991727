.SectionHome1{
    overflow: hidden;
    background-position: bottom;
    background-image:url("../images/Pass_fondo_PNG.png") ;
    background-size: cover;
    background-repeat: no-repeat;
  }
.SectionHome2{
  background-color: var(--light-grey);
}
/*1540*/
  @media (max-width: 1540px){
    .SectionHome1{
      background-position: initial;
    }
  }
  @media (max-width: 1240px){
    .SectionHome1{
      background-size: contain;
    }
  }
  @media (max-width:1125px){
    .SectionHome1{
      background-size: auto;
    }
  }