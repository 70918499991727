.Footer{
    background-image: url("../../assets/images/fondo_footer.png");
    background-color: transparent;
    background-size: cover;
  }

@media (max-width:800px){
.Footer{
    background:none;
      }
.FooterBGColor{
    margin-top: 1px;
    background: transparent linear-gradient(90deg, #E4222C 0%, #EB1F50 71%, #FA1995 100%) 0% 0% no-repeat padding-box;
}
}