.BodyFilters{
    background-color: var(--white);
    box-shadow: 0px 0px 20px #00000029;
    border-radius: 10px;
}
.ContainFilters{
    width: fit-content !important;
    align-items: center;
    display: flex;
    flex-direction: row;
    background-color: var(--light-grey);
}
.AccordionCustom{
    border: none;
}
.ContainSearchDescuentos{
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 0px 20px #00000029;
}
.ContainerCarouselDescuentos{
  
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
}
.CardDescuento{
    background-color:var(--light-grey) !important;
    border: none !important;
    height: 276px;
    width: 231px !important;
}
.ContainerImgDescuento{
    height: 232px;
    width: 231px;
}
.ImgCardDescuento{
    width: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
}
.BodyCardDescuento{
    height: 44px;
    width: 231px;
    background-color: white;
    border-radius: 0px 0px 10px 10px;
}