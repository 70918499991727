.container-viajes{
    box-shadow: 0px 0px 20px #00000029;
border-radius: 10px;
background-color: var(--white);
}
.leftMenu-subtitle{
    letter-spacing: 0.85px;
    color: var(--black);
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
}
.leftMenu-value{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 17px;
    color:var(--font-grey);
    letter-spacing: 0px;
}
.input-date{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 17px;
    color:var(--font-grey);
    letter-spacing: 0px;
}
.btn-viajes{
    border: none;
    background: transparent linear-gradient(90deg, #E4222C 0%, #EB1F50 71%, #FA1995 100%) 0% 0% no-repeat padding-box;
    border-radius: 30px 30px 30px 0px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--white);
    width: 100%;
    height: 57px;
}
.viajes-container-msg{
    background-color: #B8B8B8;
    border-radius: 2px;
}
.viajes-p-msg{
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.75px;
    color: #FFFFFF;
    text-transform: uppercase;
}
.subtitle-viajesGrey{
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 500;
}
.destacada-img{
   border-top-left-radius: 10px;
   border-bottom-left-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container-destacada{
    background-color: var(--white);
    border-radius: 10px;
}

.destacada-title{
    color:var(--red);
    font-size:30px;
    font-weight: 700;
    letter-spacing: 0px;
}
.destacada-descripcion{
    font-size: 14px;
    color:var(--font-grey);
    letter-spacing: 0px;
}
.destacada-descripcionRed{
    font-size: 14px;
    color:var(--red);
    letter-spacing: 0px;
}
.destacada-oldPrice{
    text-decoration: line-through;
    font-size: 14px;
    letter-spacing: 0px;
    color: #B8B8B8;
}
.destacada-descuento{
    padding: 2px;
    background-color: var(--pink);
    font-size: 12px;
    color: var(--white);
    letter-spacing: 0px;
    text-transform: uppercase;
}
.destacada-link{
    color:var(--red);
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    letter-spacing: 0px;
}
.destacada-minDescripcion{
    font-size: 10px;
    color: #707070;
    letter-spacing: 0px;
}
.btn-destacada{
    background-color: var(--red);
    color: var(--white);
    font-size: 14px;
    letter-spacing: 0px;
    text-transform: uppercase;
    padding: 4px 13px;
    border-radius: 15px;
    border: none;
    cursor: pointer;
}