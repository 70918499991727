.DivideMobil{
    display: none;
}
.DivideMobilLight{
    display: none;
}
.ImgQr{
    width: 54px;
    height: 74px;
}
.FooterContainInfo{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    padding: 48px 82px;
}
.ContainAppsMobil{
    display:none;
}
.TitleFooter{
    font-size: 25px;
    font-weight: 600;
    color: var(--white);
}

.FooterInfoSocialIcon{
    padding-top: 17px;
    display: flex;
    flex-direction: row;
    gap: 15px;
}
.FooterInfoContact{
    width:221px ;
}
.SubtitleFooter{
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
    text-align: left;
    letter-spacing: 0px;
    font-size: 15px;
    color: var(--white);
}
.FooterInfoBtn{
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.FooterInfoHome{
    display: flex;
    flex-direction: column;
    gap:10px;
}
.List{
    color: var(--white);
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 0.75px;
}
@media (max-width:800px){
    .DivideMobilLight{
        display: block;
        width: 100%;
       padding: 30px 52px;
    }
    .FooterInfoBtn{
        display: none;
    }
    .MobileLogo{
        width: 100px;
        height: 100px;
    }
    .ContainAppsMobil{
        display:flex;
        flex-direction: row;
    }
    .DivideMobil{
        padding: 48px 0px;
        width: 100%;
        display:block;
    }
    .FooterInfoSocialIcon{
        gap:44px;
    }
    .FooterContainInfo{
        padding: 48px 0px;
        flex-direction: column;
        align-items: center;
    }
    .TitleFooter{
        text-align: center;
        font-size: 20px;
    }
    .Display-none-mobil{
        display: none;
    }
    .ContainLegales{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px 0px;
    }

    .List{
        text-align: center;
    }
    }