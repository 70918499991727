.ContainCard{
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    width: 282px;
    height: 510px;
    box-shadow: 0px 0px 20px #00000029;
    border-radius: 10px;
    transition: all 0.5s;
}

.ContainCard:hover{
    box-shadow: 0px 0px 20px #00000040;
    transform: scale(1.015);
}
.ContainCard:hover .ContainImgMarket{
    transform: scale(1.0009);
}

.ContainImgMarket{
    width: 282px;
    height: 282px;
    transition:  all 0.5s;

}
.ImgMarket{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.TitleMarket{
    padding-left: 24px;
    padding-right: 24px;
    color:var(--font-grey);
    padding-top: 6px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;

}
.ContainOldPrice{
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
;}

.DiscountMarket{
    color: var(--white);
    background-color: var(--pink);
    font-size: 12px;
    margin-left: 5px;
   padding: 2px 10px;
}
.OldPriceMarket{
  
    font-size: 16px;
    color:var(--grey);
    text-align: center;
    text-decoration: line-through;
}
.PriceMarket{
    color:var(--black);
    text-align: center;
    font-weight: 700;
    font-size: 20px;
}
.ContainButtonMarket{
    display: flex;
    justify-content: center;
    align-items: center;
}
.btnBuy{
    border: none;
    letter-spacing: 0px;
    text-transform: uppercase;
    width: 170px;
    height: 40px;
    background-color: var(--red);
    border-radius: 50px;
    color: var(--white);
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
   
}
.btnBuy:hover{
    background-color: var(--pink);
    color: var(--white);
    transform: scale(0.87);
}