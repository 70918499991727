.ContainDerechos{
    padding-left: 83px;
    padding-right: 83px;

}

.TitleDerechos{
    font-weight: 400;
    padding-top: 16px;
    padding-bottom: 15px;
    letter-spacing: 0.25px;
    text-align: center;
    color:var(--white);
    font-size: 16px;
}