/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap'); */

.HeaderContain{
    top: 0;
    z-index: 1000;
    background: transparent linear-gradient(90deg, #E4222C 0%, #EB1F50 71%, #FA1995 100%) 0% 0% no-repeat padding-box;
    position: sticky;
    
}

.HeaderContainItems{
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.Menu{
    display:none;
}
.SubtitleContain{
    height: 24px;
    font-weight: 500;
    padding-left: 50px;
    display: flex;
    flex-direction: row;
   gap:25px;

}
.Subtitle{
    cursor: default;
    padding-bottom: 5px;
    color:var(--white);
    font-size: 15px;
    transition: border-bottom 0.1s;
}
.Subtitle::before{
    display: block;
    content: attr(textContent);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}
.Subtitle:hover{
    font-weight: bold;
    border-bottom: 4px solid #FFFFFF ;
}

.btnSoli{
    border: none;
    letter-spacing: 0.75px;
    padding: 9px 15px;
    border-radius: 35px;
    font-size: 15px;
    font-weight: bold;
    color:var(--red);
    background-color: var(--white);
    box-sizing: content-box;
}
.btnSoli:hover{
    background-color: var(--white);
    color:var(--pink);
}
.HeaderLogo:hover{
    cursor: pointer;
}
.MobileMenu{
    display: none;
}
.FullHeight{
    height: 100vh;
}
.Show{
    display: block !important;
}
@media (max-width: 1200px) {
    .SubtitleContain{
        padding-left: 25px;
        gap: 10px;
    }
     }

@media (max-width: 1069px) {
    .HeaderContain{

        margin:0px 15px;
        justify-content: space-between;
    }
    .HeaderHideMobile{
        display: none;
    }
    .Menu{
            display:block;
    }        
         }