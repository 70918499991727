.fondo-cultura{
    /* background-image: url(../../assets/images/bafici_recortado.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    position: relative;
}
.contain-video-cultura{
    position: absolute;
    height: 500px;
    width: 100%;
    z-index: -10;
}
.contain-video-cultura video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.cultura-overlay-title{
    text-align: left;
    font-size: 35px;
    font-weight: 700;
    color:var(--font-white);
}
.cultura-overlay-subtitle{
    letter-spacing: 0px;
    text-align: right;
    font-size: 20px;
    color:var(--font-white);
}
.cultura-subtitle-header{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: left;
}
.celular-cultural{
    width:100%;
    height:100%;
    object-fit: fill;
    transform: rotate(23deg);
}
.contain-celular-cultural{
    z-index: 2;
    position: absolute;
    height: 323px;
    width: 268px;
}
.contain-texto-img{
    display: flex;
    align-items: center;

    width: 191px;
    height: 90px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 0px 30px #00000029;
    border-radius: 14px;
}
.contain-texto-img p{
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    color: var(--font-grey);
}
.btn-cultura{
    border: none;
    background:var(--white);
    color:var(--pink);
    font-size: 14px;
    font-weight: 600;
    padding: 7px 30px;
    border-radius: 25px;
}
.subtitle-cultura{
    font-size: 27px;
    font-weight: 500;
    letter-spacing: 0px;
    color: var(--font-grey);
}

.carrusel-subtitle-cultura{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: var(--font-grey);
}
.img-cultura-card{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.contain-card-cultural{
    width:231px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
}
.btn-cultura-card{
    color:var(--white);
    border: none;
    background:var(--red);
    color:var(--white);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    padding: 7px 30px;
    border-radius: 25px;
}
.cultura-card-text{
    font-size: 12px;
    letter-spacing: 0px;
    color: var(--font-grey);
}
.card-cultura{
    width:231px !important;
    box-shadow: 0px 0px 5px #00000029;
    border-radius: 10px;
} 
.carrusel-contain-cultura{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
}
.overlay-map{
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: '0 2px 6px rgba(0, 0, 0, 0.3)';
    z-index: 2;
    opacity: 0.93;

}
.text-overlay-map{
    font-size: 12px;
    color: black;
    opacity: 1;
}
input[type="date" i]{
    border: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-bottom: 1px solid var(--grey);
    padding: 0.375rem 0.75rem;
    border-radius: 10px;
    border-radius: 0.25rem;

}
input[type="date" i]:focus{
    outline: none;
}

.container-locations{
    overflow-y: scroll;
}
.container-locations-prov{
    font-weight: 600;

}
.contaner-leftmenu-cultura{
    position: sticky;
    top: 110px;
}

@media (max-width: 800px){
    .cultura-overlay-title{
        font-size: 30px;
        text-align: right;
    }
    .cultura-overlay-subtitle{
        font-size: 15px;
    }
    .subtitle-cultura{
        font-size: 20px;
    }
    .cultura-subtitle-header{
        font-size: 15px;
        color: white;
        font-weight: 400;
        text-align: right;
    }
}