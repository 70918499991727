.CardCarrusel{
    flex: 1;
    display: flex;
    flex-direction: column;
}

.CardCarrusel:hover > .ContainTextCarrusel{
    background: transparent linear-gradient(90deg, #E4222C 0%, #EB1F50 9%, #FA1995 100%) 0% 0% no-repeat padding-box;
}
.ContainImgCarrusel{
    width: 260px;
    height: 200px;
}
.ImgCarrusel{
    border-top-left-radius: 45px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ContainTextCarrusel{
    padding-right: 5px;
    padding-top: 9px;
    padding-left:18px;
    height: 90px;
    width: 260px;
    background: transparent linear-gradient(90deg, #E4222C 0%, #EB1F50 71%, #FA1995 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    border-bottom-right-radius: 45px;
}
.TitleCard{
    color:var(--white);
    font-size:20px;
    font-weight: bold;
}
.DescriptionCard{
    padding-right: 5px;
    letter-spacing: 0px;
    color:var(--white);
    font-size:16px;
}