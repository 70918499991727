
.ContainMarket{
    padding-top: 54px;
}
.ContainCards{
    overflow-x: auto;
    justify-content: flex-start;
    gap:24px;
    display: flex;
    flex-direction: row;
    padding-bottom: 25px;
}

@media (max-width:800px){
    .ContainMarket{
        padding-left: 5px;
        padding-right: 5px;
    }
}