.btn{
    
    border-radius: 2px;
    padding: 6px 14px;
    font-size: 11px;
    letter-spacing: 1.5px;
    border: none;
    color: var(--white);
    height: 26px;
}
.subtitle{
    font-weight: 500;
    font-size: 12px;
    color: var(--red);
    text-align: center;
    margin-bottom: 0px;
}
.title{
    font-size: 15px;
    font-weight: bold;
    color: var(--red);
    text-align: center;
    margin-bottom: 0px;
}
.BtnContain{
    gap: 9px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.TextContain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.btnApp {
    border: none;
    z-index: 30;
    padding: 14px 22px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
}
p{
    margin: 0px;
}
.btnApp:hover .title,.btnApp:hover .subtitle{
   color:var(--pink);
}