.SubscribiteTitle{
    color: var(--red);
    font-weight: 700;
    text-align: center;
    font-size: 36px;
    text-align: center;
}
.CardTitleSub{
    padding-top: 20px;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
    color:var(--font-grey);
}
.CardDescSub{
    line-height: 30px;
    color: var(--font-grey);
    font-size: 20px;
    text-align: center;
    letter-spacing: 0px;
    font-weight: 400;
}
.SubscribiteButton{
    font-size: 20px;
    font-weight: 500;
    border-radius: 30px;
    padding: 10px 15px;
    border: none;
    background-color: var(--red);
    color: var(--white);
}
.SubscribiteButton:hover{
    background-color: var(--pink);
}
.CardContainSub{
    padding: 40px 15px;
    background-color: var(--white);
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    transition: all 0.3s ease-in-out;
}
.CardContainSub:hover{
    box-shadow: 0px 0px 20px #00000040;
}
.DescSub{
    font-size: 20px;
    text-align: center;
}
@media (max-width: 768px) {
    .SubscribiteTitle{
        font-size: 30px;
    }
    .DescSub{
        font-size: 18px;
    }
}