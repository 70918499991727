.btnQuieroDescuento{
    border: none;
    background: transparent linear-gradient(90deg, #E4222C 0%, #EB1F50 8%, #FA1995 100%) 0% 0% no-repeat padding-box;
    border-radius: 50px;
    color: var(--white);
    font-size: 20px;
    font-weight: 700;
    padding: 15px 40px;

}
.ContainCodCupon{
    border-radius: 30px;
    background-color: var(--pink);
    width: fit-content;
}

.PDescripcionCupon{
    color: var(--white);
    letter-spacing: 0;
    font-weight: 400;
}
.TitleCupon{
    color: var(--white);
    font-size: 20px;
    font-weight: 700;
}
.CodCupon{
    border: 1px solid var(--white);
    letter-spacing: 3px;
    font-size: 25px;
    color: var(--white);
}
.ContainImgDescuento{
    width: 340px;
    height: 340px;
}
.ImgDescuento{
    border-radius: 15px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ContainerCardDescuento{
    border-radius: 10px;
    box-shadow: 0px 0px 20px #00000029;
}
.LogoComercioDescuento{
    height: 100%;
    object-fit: cover;
}
.ContainLogoComercio{
  height: 100px;
}

.PPromo{
    font-size: 24px;
    color: var(--font-grey);
    font-weight: 700;
    text-transform: uppercase;
}
.PPromocion{
    letter-spacing: 0px;
    font-size: 16px;
    color: var(--font-grey);
    font-weight: 500;
}
.PTipoCompra{
    letter-spacing: 0px;
    font-size: 16px;
    color: var(--red);
}
.PHrefDescuentos{
    letter-spacing: 0;
    font-size: 16px;
    font-weight: 400;
}
.H3Subtitle{
    font-size: 24px;
    color: var(--font-grey);
    font-weight: 600;
}
.PDescripcionDescuento{
    letter-spacing: 0;
    font-size: 16px;
    color: var(--font-grey);
    font-weight: 400;
}
.btnQuieroCredencial{
    background-color: var(--white);
    color: var(--pink);

}
@media (max-width:500px) {
    .LogoComercioDescuento{
        width: 100%;
    }
}