.img-preview-marketpalce{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid #ccc;
}
.contain-text-producto{
    box-shadow: 0px 0px 20px #00000029;
border-radius: 10px;
}

.text-info-market{
    color:var(--red);
    font-size: 14px;
    font-weight: 500;
}
.btn-marketplace{
    background: transparent linear-gradient(90deg, #E4222C 0%, #EB1F50 71%, #FA1995 100%) 0% 0% no-repeat padding-box;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0px;
    border: none;
    border-radius: 25px;
    padding: 10px 30px;
    cursor: pointer;
}
.text-title-product{
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0px;
    color: var(--font-grey);
}

.text-title-comparacion{
    font-size: 20px;
    letter-spacing: 0px;
    font-weight: 600;
    color: var(--font-grey);
}
.text-subtitle-comparacion{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: var(--font-grey);
}
.text-comparacion{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: var(--font-grey);
}

.contain-comparacion{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #00000029;
    border-radius: 10px;
}
.text-comparacion-black{
    font-size: 14px;
    color: var(--black);
}
.text-comparacion-title-grey{
    font-size: 16px;
    font-weight: 500;
    color: var(--font-grey);
}